<script setup lang="ts">
    import type { UspItemData, UspWidgetData } from '~/@types/cms';
    import type { IWidgetProps } from '~/composables/widgets';

    /**
     * USP widget container
     * renders a list of USP info blocks
     */
    const props = defineProps<IWidgetProps<UspWidgetData>>();
    const { resolveUrlLink } = useURL();
    const { data, getValueSequence } = useWidget(props);
    const items = computed(() => {
        if (!data.value) return [];
        const sequence = getValueSequence(data.value);
        if (!sequence) return [];
        return sequence
            .map((u: UspItemData) => {
                const { url } = resolveUrlLink(u.link);
                const content = u.title;
                const icon = u.icon?.data?.icon;
                if (!url || !content || !icon) return;

                return {
                    content,
                    icon,
                    toLink: url,
                };
            })
            .filter(isDefined);
    });
</script>
<template>
    <mol-usp-bar
        :items="items"
        class="cms-usp-widget-container" />
</template>
